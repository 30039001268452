export default function Motto() {
  return (
    <div
      className="max-w-[1120px] m-auto px-4 py-12 flex flex-col justify-between items-center my-36"
      data-aos="fade-down"
      data-aos-duration="3000"
    >
      <h3 className="mt-6 font-[fraunces] md:text-5xl text-3xl">
        Every object has a meaning and the power to evoke powerful emotions.
      </h3>
    </div>
  );
}
