import Hero from "../components/Home/Hero";
import Middle from "../components/Home/Middle";
import MidSection from "../components/Home/MidSection";
import Gallery from "../components/Home/Gallery";
export default function Home() {
  return (
    <div className="">
      <Hero />
      <Middle />
      <MidSection />
      <Gallery />
    </div>
  );
}
